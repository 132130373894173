export enum ShangDongCities {
    // 山东济南市
    JiNan = '370100',
    // 山东济宁市
    JiNing = '370800'
}

export enum ComponentName {
    default = 'EpidemicForm',
    // 山东济南市
    JiNan = 'EpidemicFormJiNan',
    // 山东济宁市
    JiNing = 'EpidemicFormJiNing'
}

// 常州城市编码
export const ChangZhouCode = '320400'
