











































































import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { Dialog, Toast } from 'vant'
import dayjs from 'dayjs'
import { v4 as uuid } from 'uuid'
import EpidemicForm from './epidemic-form.vue'
import EpidemicFormJiNan from './epidemic-form-ji-nan.vue'
import EpidemicFormJiNing from './epidemic-form-ji-ning.vue'
import { ShangDongCities, ComponentName } from './constants'
import { JiNingDictData, PSBFirm } from '@/self-service-registration/http/api/index/interfaces'
import { compress, checkStatus, getHotelInfo, getJiNingDict, getRegionData, ocr } from '@/self-service-registration/http/api/index'
import { checkIdCard, getBirthdayById } from '@/helper'
import { isArray, isObject } from '@/helper/is'
import { chineseCharacterReg, nameReg, yangZhouTelReg } from '@/helper/tests'
import { MoreData, Region } from '@/http/index/interfaces'
import { getCityAndMoreInfoData, getServerTime } from '@/http/index'
// @ts-ignore
import wx from 'weixin-js-sdk'

export default Vue.extend({
    props: {
        outerHotelCode: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            initialized: false,
            serverTime: 0,
            componentName: ComponentName.default,
            moreInfoRps: [] as MoreData[],
            cities: [] as Region[],
            jiNingDict: {
                operationArriveType: [],
                operationComeType: [],
                operationHealthState: []
            } as JiNingDictData,
            nationColumns: [] as { id: string; text: string }[],
            nationPickerVisible: false
        }
    },
    computed: {
        ...mapGetters(['hotelId', 'hotelCode', 'psbFirm', 'hotelCity', 'hotelName', 'phone', 'cardInfo', 'epidemicFlag'])
    },
    async created() {
        try {
            if (this.outerHotelCode) {
                Toast.loading({
                    message: '请稍后...',
                    duration: 0,
                    forbidClick: true
                })
                const serverTimeRes = getServerTime()
                const res = await getHotelInfo(this.outerHotelCode)
                this.serverTime = ((await serverTimeRes) as number) * 1000
                const { id, hotelCode, hotelName, province, city, psbType, registrationFlag, nations } = res
                if (nations) {
                    this.nationColumns = nations.map((c) => {
                        return {
                            id: c.code,
                            text: c.name
                        }
                    })
                }
                this.updateData({
                    hotelId: id,
                    hotelCode,
                    hotelName,
                    hotelProvince: province,
                    hotelCity: city,
                    psbFirm: psbType,
                    epidemicFlag: registrationFlag === 'yes' ? 1 : 0
                })
                this.calcCurrentComponentName()
                await this.getDict()
            }
        } finally {
            this.initialized = true
            Toast.clear()
        }
    },
    methods: {
        ...mapMutations(['updateData', 'updateCardInfo', 'clearCardInfo']),
        ...mapActions(['setStep']),
        calcCurrentComponentName() {
            const map: Record<string, { [key: string]: ComponentName }> = {
                [PSBFirm.daYin]: {
                    [ShangDongCities.JiNan]: ComponentName.JiNan,
                    [ShangDongCities.JiNing]: ComponentName.JiNing
                }
            }
            const { psbFirm, hotelCity } = this
            if (map[psbFirm]) {
                if (map[psbFirm][hotelCity]) {
                    this.componentName = map[psbFirm][hotelCity]
                } else {
                    this.componentName = ComponentName.default
                }
            } else {
                this.componentName = ComponentName.default
            }
        },
        getDict() {
            const map: Record<string, () => Promise<void>> = {
                [ComponentName.default]: this.getCityAndMoreInfoData,
                [ComponentName.JiNan]: this.getRegionData,
                [ComponentName.JiNing]: this.getJiNingDict
            }
            return map[this.componentName]?.()
        },
        getRegionData() {
            return getRegionData().then((res) => {
                if (isArray(res)) this.cities = res
            })
        },
        getCityAndMoreInfoData() {
            return getCityAndMoreInfoData(this.hotelId).then((res) => {
                if (isArray(res.citySourceRps)) this.cities = res.citySourceRps
                if (isArray(res.moreInfoRps)) this.moreInfoRps = res.moreInfoRps
            })
        },
        getJiNingDict() {
            return getJiNingDict().then((res) => {
                Object.assign(this.jiNingDict, res)
            })
        },
        onChange(value: string | number | string[], key: string) {
            this.updateData({ [key]: value })
        },
        // onInputForPhone(e: Event) {
        //     const target = e.target as HTMLInputElement
        //     if (target.value.length > 11) target.value = target.value.slice(0, 11)
        //     // iOS change 事件失效
        //     this.onChange(target.value, 'phone')
        // },
        onInputChange(e: Event, key: string, isCard = false) {
            const target = e.target as HTMLInputElement
            if (isCard) {
                this.updateCardInfo({
                    [key]: target.value
                })
            } else {
                this.onChange(target.value, key)
            }
        },
        checkStatus(confirmButtonText = '知道了', cardId: string) {
            return checkStatus(this.hotelId, cardId).then((res) => {
                const { code, message } = res
                if (code === 0) {
                    return true
                } else if (code === 1 || code === 2) {
                    let message = '已进行自助登记，请勿重复登记。'
                    if (code === 2) message = '已办理入住，请勿重复进行宾客登记。'
                    Dialog.alert({
                        message,
                        confirmButtonText,
                        confirmButtonColor: '#4AC3BB'
                    }).then(() => {
                        this.clearCardInfo()
                    })
                    return false
                } else {
                    Toast(message || '系统异常')
                    return false
                }
            })
        },
        onRadioClick(e: number, key: string) {
            if (e !== this.cardInfo[key]) {
                this.updateCardInfo({ [key]: e })
            }
        },
        onNationConfirm(e: { id: string; text: string }) {
            this.updateCardInfo({
                nationality: e.text,
                nationalityCode: e.id
            })
            this.nationPickerVisible = false
        },
        async beforeUploadCard() {
            if (!this.hotelCode) {
                Toast('请先录入酒店名称')
                return false
            }
            ;(this.$refs.file as HTMLInputElement).click()
        },
        onFileChange(e: Event) {
            const target = e.target as HTMLInputElement
            let file: File | null = target.files![0]
            target.value = ''
            if (!file) return

            if (file.type.indexOf('image') === -1) {
                Toast('请选择图片')
                return
            }
            const size = Math.round(file.size / 1000)
            if (size > 10000) {
                Toast('图片太大，请压缩后重试')
                return
            }
            // compress(file).then((newFile) => {
            //     file = null
            //     const size = Math.round(newFile.size / 1000)
            //     if (size > 10000) {
            //         Toast('图片太大，请压缩后重试')
            //         return
            //     }
            //     const toast = Toast.loading({
            //         message: '请稍后...',
            //         duration: 0,
            //         forbidClick: true
            //     })
            //     this.ocr(newFile).finally(toast.clear)
            // }).catch(err => {
            //     Toast('图片压缩失败，请重新更换图片后重试')
            //     console.error(err)
            //     throw err
            // })
            const toast = Toast.loading({
                message: '请稍后...',
                duration: 0,
                forbidClick: true
            })
            this.ocr(file).finally(toast.clear)
        },
        async ocr(file: File) {
            const showModal = () => {
                Dialog.alert({
                    message: '由于您的身份证照片清晰度低或照片不完整，请重新上传身份证人像面。',
                    confirmButtonText: '知道了',
                    confirmButtonColor: '#4AC3BB'
                }).then(() => {
                    this.clearCardInfo()
                })
            }

            try {
                const formData = new FormData()
                formData.append('img', file)
                formData.append('ocrType', 'IDCARD')
                formData.append('microProgramType', 'BOSS')
                const { code, message, result } = await ocr(formData)
                if (code === 0) {
                    const { type, name, gender, nationality, addr, id, imgUrl } = result
                    if (type === 'Front' && name && gender && nationality && addr && id && (id.length === 15 || id.length === 18)) {
                        const status = await this.checkStatus('知道了', id)
                        if (!status) return
                        let nationalityCode = ''
                        const nationData = this.nationColumns.find((c) => c.text.startsWith(nationality))
                        if (nationData) {
                            nationalityCode = nationData.id
                        } else {
                            const nationData = this.nationColumns.find((c) => c.text === '汉族')
                            nationalityCode = nationData?.id ?? '01'
                        }
                        const compressFormData = new FormData()
                        compressFormData.append('file', file)
                        compressFormData.append('fileType', 'IMG')
                        compressFormData.append('desFileSize', '4000')
                        compressFormData.append('sideSize', '2000')
                        const imgUrl = await compress(compressFormData)
                        this.updateData({
                            cardInfo: {
                                isScan: true,
                                id,
                                name,
                                gender,
                                genderCode: gender === '男' ? 1 : 2,
                                nationality: `${nationality}族`,
                                nationalityCode,
                                addr,
                                imgUrl,
                                birthday: getBirthdayById(id)
                            }
                        })
                    } else {
                        showModal()
                    }
                } else if (code === 101003) {
                    Toast(message || '缺少服务调用次数')
                } else if (code === 500) {
                    Toast('系统异常')
                } else {
                    showModal()
                }
            } catch (err) {
                this.clearCardInfo()
                if (isObject(err)) {
                    Toast(err.message || '系统异常')
                } else {
                    Toast('系统异常')
                }
                throw err
            }
        },
        validate18Age() {
            const current = dayjs(this.serverTime)
            const dateOfAdulthood = dayjs(this.cardInfo.birthday).add(18, 'year')
            if (dateOfAdulthood.isSame(current, 'date') || dateOfAdulthood.isBefore(current, 'date')) {
                return true
            }
            Dialog.alert({
                message: '未满18周岁人员，请至前台登记。',
                confirmButtonText: '确定',
                confirmButtonColor: '#4AC3BB'
            })
            return false
        },
        validate(isValidateCard = false) {
            const { hotelCode, phone, cardInfo } = this
            let errorMsg = ''

            const setErrorMsg = (msg: string) => {
                if (!errorMsg) errorMsg = msg
            }

            if (!isValidateCard) {
                if (!hotelCode) setErrorMsg('请先录入酒店名称')
                if (!phone) setErrorMsg('请输入手机号')
                if (!yangZhouTelReg.test(phone)) setErrorMsg('请输入正确的手机号')
            }

            if (isValidateCard) {
                const { isScan, id: realId, name: realName, nationalityCode, addr } = cardInfo
                if (!isScan) setErrorMsg('请添加身份证人像面')
                const name = realName.trim()
                if (name) {
                    if (name.length < 2) setErrorMsg('姓名长度2-30个字符')
                    if (!nameReg.test(name)) setErrorMsg('请输入正确的姓名')
                    if (!chineseCharacterReg.test(name[0]) || !chineseCharacterReg.test(name[name.length - 1])) {
                        setErrorMsg('请输入正确的姓名')
                    }
                } else {
                    setErrorMsg('请输入姓名')
                }

                if (!nationalityCode) setErrorMsg('请选择民族')

                if (!addr || !addr.trim()) setErrorMsg('请输入住址')
                if (addr.trim().length < 4) setErrorMsg('住址不能少于4个字符')

                const id = realId.trim()
                if (!id) setErrorMsg('请输入证件号码')
                if (checkIdCard(id)) {
                    // 修改身份证号码后要更改出生日期
                    this.updateCardInfo({
                        birthday: getBirthdayById(id)
                    })
                } else {
                    setErrorMsg('请输入正确的证件号码')
                }
            }
            if (errorMsg) {
                Toast(errorMsg)
                return false
            }
            return true
        },
        submit() {
            console.log(uuid());
            wx.ready(() => {
                wx.invoke('chooseInvoice', {
                    'timestamp': Math.floor(Date.now() / 1000),
                    'nonceStr': uuid().substring(0 ,32),
                }, (res: any) => {
                    console.log(res);
                    console.log(JSON.stringify(res));
                })

                setTimeout(() => {
                    wx.invoke('chooseInvoiceTitle', {
                        "scene":"1"
                    }, (res: any) => {
                        console.log(res);
                        console.log(JSON.stringify(res));
                    })
                }, 5000)
            })

            // if (!this.validate()) return
            // if (this.epidemicFlag === 1 && !(this.$refs.formComponent as any).validate()) return
            // if (!this.validate(true)) return
            // if (!this.validate18Age()) return
            // const toast = Toast.loading({
            //     message: '请稍后...',
            //     duration: 0,
            //     forbidClick: true
            // })
            // this.checkStatus('确定', this.cardInfo.id)
            //     .then((res) => {
            //         if (res) this.setStep(2)
            //     })
            //     .finally(toast.clear)
        },
        submit2() {
            console.log('submit2submit2');
            wx.chooseImage({
                count: 1, // 默认9
                sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
                sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
                success(res: any) {
                    console.log(res);
                }
            });


        }
    },
    components: {
        EpidemicForm,
        EpidemicFormJiNan,
        EpidemicFormJiNing
    }
})
