




















































































import Vue from 'vue'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import dayjs from 'dayjs'
import { Toast } from 'vant'
import { getterKeys } from '@/self-service-registration/store/step1'
import { PSBFirm } from '@/self-service-registration/http/api/index/interfaces'
import { ChangZhouCode, ComponentName } from './constants'
import RegionPicker from '@/components/region-picker/index.vue'
import { Column } from '@/http/index/interfaces'

export default Vue.extend({
    name: ComponentName.default,
    props: {
        serverTime: {
            type: Number,
            required: true
        },
        cities: {
            type: Array,
            required: true
        },
        moreInfoRps: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            PSBFirm,
            ChangZhouCode,
            wayCityPickerVisible: false,
            datePickerVisible: false,
            regionPickerVisible: false,
            detectionTime: null as Date | null,
            minDate: new Date(2020, 0, 1),
            maxDate: dayjs(this.serverTime || Date.now()).toDate(),
            more: [] as number[],
            wayCities: [] as { id: string; text: string }[]
        }
    },
    computed: {
        ...mapGetters(getterKeys)
    },
    methods: {
        ...mapMutations(['updateData', 'clearCardInfo']),
        ...mapActions(['setStep']),
        onChange(value: string | number | string[], key: string) {
            this.updateData({ [key]: value })
        },
        onInputChange(e: Event, key: string) {
            const target = e.target as HTMLInputElement
            this.onChange(target.value, key)
        },
        onRadioClick(e: number, key: string) {
            if (e !== this[key]) {
                this.onChange(e, key)
            }
        },
        onWayCityDone(data: Column[]) {
            if (data.length) {
                const city = data[data.length - 1]
                const res = this.strWLZGFXDQ.find((c: string) => c === city.id)
                if (!res) {
                    const { wayCities } = this
                    wayCities.push(city)
                    this.onChange(
                        wayCities.map((c) => c.id),
                        'strWLZGFXDQ'
                    )
                }
            }
        },
        removeWayCity(id: string) {
            const { wayCities } = this
            const index = wayCities.findIndex((c) => c.id === id)
            if (index >= 0) {
                wayCities.splice(index, 1)
                this.onChange(
                    wayCities.map((c) => c.id),
                    'strWLZGFXDQ'
                )
            }
        },
        onBGSSJConfirm(e: Date) {
            this.onChange(dayjs(e).format('YYYY-MM-DD HH:mm'), 'strBGSSJ')
            this.datePickerVisible = false
        },
        validate() {
            let errorMsg = ''
            const setErrorMsg = (msg: string) => {
                if (!errorMsg) errorMsg = msg
            }

            const { hotelCity, wayCities, strBGS, strBGSSJ, strBGSJCJG } = this
            if (!wayCities.length) setErrorMsg('请选择14日内途径城市')

            if (hotelCity === ChangZhouCode) {
                if (typeof strBGS !== 'number') setErrorMsg('请选择核酸检测报告')
                if (strBGS === 1) {
                    if (!strBGSSJ) setErrorMsg('请选择核酸检测报告时间')
                    if (!strBGSJCJG || !strBGSJCJG.trim()) setErrorMsg('请输入检测报告机构')
                }
            }

            if (errorMsg) {
                Toast(errorMsg)
                return false
            }
            return true
        }
    },
    watch: {
        strWLZGFXDQ(value: string[], oldValue: string[]) {
            // 返回时清除数据
            if (value.length === 0 && oldValue.length > 0) {
                this.wayCities = []
            }
        },
        xzgdqk(value: number[], oldValue: number[]) {
            // 返回时清除数据
            if (value.length === 0 && oldValue.length > 0) {
                this.more = []
            }
        }
    },
    components: {
        RegionPicker
    }
})
