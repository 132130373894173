import { GetterTree, Module } from 'vuex'
import clonedeep from 'lodash.clonedeep'
import { Toast } from 'vant'
import { RootState } from '../interfaces'
import { Step1State } from './interfaces'
import { yangZhouTelReg } from '@/helper/tests'
import { PSBFirm } from '@/self-service-registration/http/api/index/interfaces'
import { saveTeamCheckIn } from '@/self-service-registration/http/api/index'

const defaultData: Step1State = {
    hotelId: 0,
    hotelCode: '',
    hotelName: '',
    hotelCity: '',
    hotelProvince: '',
    psbFirm: PSBFirm.default,
    phone: '',
    xzgdqk: [],
    provinceName: '',
    city: null,
    cityName: '',
    district: null,
    districtName: '',
    strWLZGFXDQ: [],
    strSFZGFXLJS: 0,
    strBGS: 0,
    strBGSSJ: undefined,
    strBGSJCJG: '',
    healthCode: 1,
    strLXBDBR: 1,
    strJCGWYQ: 0,
    strDDFS: undefined,
    strDDFSXQ: '',
    strTJSS: '',
    strDDSS: undefined,
    strLDFS: undefined,
    strLDFSXQ: undefined,
    strDQTW: '',
    score: '',
    strCome: '',
    strDQZK: undefined,
    strSFGLJN: undefined,
    strSFJC: undefined,
    strTXRY: undefined,
    epidemicFlag: 0,
    cardInfo: {
        isScan: false,
        id: '',
        name: '',
        gender: '',
        genderCode: 1,
        nationality: '',
        nationalityCode: '',
        addr: '',
        imgUrl: '',
        birthday: ''
    },
    // cardInfo: {
    //     isScan: true,
    //     id: '320323200008305844',
    //     name: '达到',
    //     gender: '男',
    //     genderCode: 1,
    //     nationality: '汉族',
    //     nationalityCode: '01',
    //     addr: '奥术大师多',
    //     imgUrl: 'https://weixingateway-sit-1302645133.cos.ap-shanghai.myqcloud.com/5038ea48-b3aa-408e-9618-d8868f24c6f98d6d12e7-b015-4709-b4ac-63bf4bdd1758470102cb-5451-4310-bd7f-90502e4be177Zq2xXT7wcK7H0b1af3e08c356b04978af0206b8ad39f.jpeg',
    //     birthday: ''
    // }
}

export const getterKeys = Object.keys(defaultData)

const getters: GetterTree<Step1State, RootState> = Object.create(null)
getterKeys.forEach((c) => {
    getters[c] = (state) => state[c as keyof Step1State]
})

const step1: Module<Step1State, RootState> = {
    state: clonedeep(defaultData),
    getters,
    mutations: {
        updateData(state, value: Partial<Step1State>) {
            Object.assign(state, value)
        },
        updateCardInfo(state, value: Partial<Step1State['cardInfo']>) {
            Object.assign(state.cardInfo, value)
        },
        clearCardInfo(state) {
            Object.assign(state.cardInfo, {
                isScan: false,
                id: '',
                name: '',
                gender: '',
                genderCode: 1,
                nationality: '',
                nationalityCode: '',
                addr: '',
                imgUrl: '',
                birthday: ''
            })
        }
    },
    actions: {
        async validate({ state }) {
            const { hotelCode, phone, cardInfo } = state
            let errorMsg = ''

            const setErrorMsg = (msg: string) => {
                if (!errorMsg) errorMsg = msg
            }

            if (!hotelCode) setErrorMsg('请先录入酒店名称')

            if (!phone) setErrorMsg('请输入手机号')

            if (!yangZhouTelReg.test(phone)) setErrorMsg('请输入正确的手机号')

            if (!cardInfo.id) setErrorMsg('请添加身份证人像面')

            if (errorMsg) {
                Toast(errorMsg)
                return false
            }
            return true
        },
        reset({ commit, state }) {
            commit(
                'updateData',
                Object.assign(clonedeep(defaultData), {
                    hotelId: state.hotelId,
                    hotelCode: state.hotelCode,
                    hotelName: state.hotelName,
                    hotelProvince: state.hotelProvince,
                    hotelCity: state.hotelCity,
                    psbFirm: state.psbFirm,
                    epidemicFlag: state.epidemicFlag
                })
            )
        },
        saveTeam({ state, dispatch }) {
            return saveTeamCheckIn({
                hotelId: state.hotelId,
                psbFirm: state.psbFirm,
                guestInfoRq: {
                    name: state.cardInfo.name.trim(),
                    birthday: state.cardInfo.birthday,
                    idCardNo: state.cardInfo.id.trim(),
                    sex: state.cardInfo.genderCode,
                    nation: state.cardInfo.nationalityCode,
                    phone: state.phone,
                    address: state.cardInfo.addr.trim(),
                    photo: state.cardInfo.imgUrl,
                    cameraImg: state.cameraImg!,
                    score: state.score!,
                    consistentFlag: 1,
                    verifiedSign: '0',
                    infoType: '11',
                    gIsMinor: '0',
                    strWLZGFXDQ: state.strWLZGFXDQ,
                    strLXFS: state.phone,
                    strJCGWYQ: state.strJCGWYQ!,
                    strBGS: state.strBGS!,
                    strBGSSJ: state.strBGSSJ,
                    strBGSJCJG: state.strBGSJCJG,
                    strLXBDBR: state.strLXBDBR,
                    xzgdqk: state.xzgdqk,
                    psbConsistentFlag: 1,
                    healthCode: state.healthCode,
                    strSFZGFXLJS: state.strSFZGFXLJS,
                    strCome: state.strCome,
                    strDDFS: state.strDDFS,
                    strDDFSXQ: state.strDDFSXQ,
                    strTJSS: state.strTJSS,
                    strDDSS: state.strDDSS,
                    strLDFS: state.strLDFS,
                    strLDFSXQ: state.strLDFSXQ,
                    strDQZK: state.strDQZK,
                    strDQTW: state.strDQTW,
                    strSFGLJN: state.strSFGLJN,
                    strSFJC: state.strSFJC,
                    strTXRY: state.strTXRY,
                    epidemicFlag: state.epidemicFlag!,
                    countryKbn: 1,
                    inputMethodName: '',
                    inputMethodCode: ''
                }
            }).catch((err) => {
                dispatch('setStep', 1)
                throw err
            })
        }
    }
}

export default step1
