



















import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'
import { Toast } from 'vant'
import { ComponentName } from './constants'
import RegionPicker from '@/components/region-picker/index.vue'
import { Column } from '@/http/index/interfaces'

export default Vue.extend({
    name: ComponentName.JiNan,
    props: {
        cities: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            visible: false,
            area: ''
        }
    },
    computed: {
        ...mapGetters(['strCome', 'healthCode'])
    },
    methods: {
        ...mapMutations(['updateData']),
        onRadioClick(e: number) {
            this.updateData({ healthCode: e })
        },
        onDone(data: Column[]) {
            this.area = data.map((c) => c.text).join(' ')
            this.updateData({ strCome: data.map((c) => c.id).join(',') })
        },
        validate() {
            let errorMsg = ''
            const setErrorMsg = (msg: string) => {
                if (!errorMsg) errorMsg = msg
            }
            if (!this.strCome) setErrorMsg('请选择何处来')
            if (errorMsg) {
                Toast(errorMsg)
                return false
            }
            return true
        }
    },
    watch: {
        strCome(val?: string) {
            if (!val) this.area = ''
        }
    },
    components: {
        RegionPicker
    }
})
