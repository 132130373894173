import 'lib-flexible'
import Vue from 'vue'
import { Toast, Button, Icon, NavBar, Popup, Picker, RadioGroup, Radio, DatetimePicker, Dialog, Checkbox, CheckboxGroup } from 'vant'
import App from './App.vue'
import store from './store'
import '@/assets/scss/index.scss'
// import { isSit } from '@/utils/is'
import { SentryInit } from './plugins/sentry'
// @ts-ignore
import wx from 'weixin-js-sdk'
import { getWxConfigData } from '@/http/index'
console.log('wxwxwx')
console.log(wx)
const VConsole = require('vconsole')
new VConsole()

getWxConfigData().then((res) => {
    console.log('resresres')
    console.log(res)
    wx.config({
        debug: true,
        appId: 'wx969093045270130c',
        timestamp: Number(res.timestamp),
        nonceStr: res.noncestr,
        signature: res.signature,
        jsApiList: ['chooseInvoice', 'chooseInvoiceTitle', 'chooseImage']
    })
    wx.error((res: any) => {
        console.log('catch config error :: =============== ::');
        console.log(res);
    })

    wx.ready(() => {
        wx.checkJsApi({
            jsApiList: [
                'chooseInvoice',
                // 所有要调用的 API 都要加到这个列表中
                'chooseInvoiceTitle',
                'chooseImage'
            ], // 需要检测的JS接口列表，所有JS接口列表见附录2,
            success(res: any) {
                console.log('checkJsApi');
                console.log(res);
                console.log(JSON.stringify(res));
            }
        });
    })
})

// wx.ready(function () {
//     wx.invoke('chooseInvoice', {
//         'timestamp': Math.floor(Date.now() / 1000),
//         'nonceStr': "p(6N&7WOAF",
//     }, (res: any) => {
//         console.log(JSON.stringify(res));
//     })
// })

Toast.allowMultiple(true)
Vue.use(Toast)
Vue.use(Button)
Vue.use(Icon)
Vue.use(NavBar)
Vue.use(Popup)
Vue.use(Picker)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(DatetimePicker)
Vue.use(Dialog)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)

Vue.config.productionTip = false

new Vue({
    store,
    render: (h) => h(App)
}).$mount('#app')

SentryInit()
