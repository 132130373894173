

















import Vue from 'vue'

export default Vue.extend({
    props: {
        step: {
            type: Number,
            default: 1
        }
    }
})
