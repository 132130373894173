



















import Vue from 'vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { Toast } from 'vant'
import { Status } from './interfaces'
import { invokeCamera } from './camera'
import { wait } from '@/helper'
import { compress, faceCompare, getSelfCheck, saveSelfCheck } from '@/self-service-registration/http/api/index'

export default Vue.extend({
    data() {
        return {
            isPassed: false,
            strokeVisible: false,
            visible: false,
            status: Status.wait,
            Status,
            score: 0,
            videoVisible: false,
            needUserGesture: false
        }
    },
    computed: {
        ...mapGetters(['step', 'hotelId', 'cardInfo']),
        maskName(): string {
            const { name }: { name: string } = this.cardInfo
            const len = name.length
            if (len > 1) return name[len - 1].padStart(len > 3 ? 3 : len, '*')
            return name
        }
    },
    mounted() {
        const { video } = this.$refs as { video: HTMLVideoElement }
        invokeCamera(video, (res) => {
            this.visible = true
            if (res) {
                this.run()
            } else {
                this.needUserGesture = true
            }
        })
    },
    methods: {
        ...mapMutations(['updateData']),
        ...mapActions(['setStep', 'saveTeam']),
        play() {
            const { video } = this.$refs as { video: HTMLVideoElement }
            if (video.paused) {
                video
                    .play()
                    .then(() => {
                        this.run()
                    })
                    .catch((err) => {
                        Toast('系统异常，请稍后重试')
                        throw err
                    })
            } else {
                this.run()
            }
        },
        async run() {
            if (this.status === Status.pending || this.status === Status.success) return
            this.status = Status.pending
            try {
                const { id, imgUrl } = this.cardInfo
                const checkRes = await getSelfCheck(id, this.hotelId)
                if (checkRes.code === 0) {
                    if (checkRes.result) {
                        this.status = Status.wait
                        Toast({
                            message: '核验未通过次数已超过三次，请联系前台办理入住。',
                            duration: 5000,
                            forbidClick: true
                        })
                        return
                    }
                } else {
                    this.status = Status.wait
                    Toast(checkRes.message || '系统异常')
                    return
                }

                await wait(3000)
                if (this.step !== 2) return
                const file = await this.screenShot()
                const formData = new FormData()
                formData.append('file', file)
                formData.append('fileType', 'IMG')
                formData.append('desFileSize', '4000')
                formData.append('sideSize', '2000')
                const url = await compress(formData)
                const res = await faceCompare(imgUrl, url).finally(() => {
                    return saveSelfCheck(id, this.hotelId)
                })
                const score = Math.floor(res.result ?? 0)
                this.score = score

                if (res.code === 0) {
                    this.updateData({
                        cameraImg: url,
                        score
                    })
                    await this.saveTeam()
                    this.status = Status.success
                    this.setStep(3)
                } else {
                    this.status = Status.fail
                }
            } catch (err) {
                this.status = Status.fail
                throw err
            }
        },
        screenShot() {
            return new Promise<Blob>((resolve, reject) => {
                const { video } = this.$refs as { video: HTMLVideoElement }
                let canvas: HTMLCanvasElement | null = document.createElement('canvas')
                canvas.width = video.videoWidth
                canvas.height = video.videoHeight
                const ctx = canvas.getContext('2d')
                if (ctx) {
                    ctx.scale(-1, 1)
                    ctx.drawImage(video, 0, 0, -video.videoWidth, video.videoHeight)
                    canvas.toBlob((blob) => {
                        blob ? resolve(new File([blob], 'xianchangzhao.png', { lastModified: Date.now(), type: blob.type })) : reject()
                        canvas!.remove()
                        canvas = null
                    })
                } else {
                    canvas!.remove()
                    canvas = null
                    reject()
                }
            })
        }
    }
})
