import Vue from 'vue'
import { importWithRetry } from '@/helper'
import { isErrorObject } from '@/helper/is'
import store from '../store'

const DsnMap: Record<string, string> = {
    prod: 'https://d798871a1a4a4f9a86680c4795adccd5@sentry.whalepms.com/11',
    sit: 'https://0939a70e5f484f2ba77e4e696f04c22f@sentry.whalepms.com/9'
}

export const SentryInit = () => {
    const dsn = DsnMap[process.env.VUE_APP_MODE || '']
    if (dsn) {
        Promise.allSettled([
            importWithRetry(() => import(/* webpackChunkName: "sentry" */ '@sentry/browser')),
            importWithRetry(() => import(/* webpackChunkName: "sentry" */ '@sentry/integrations'))
        ]).then((results) => {
            const [SentryRes, IntegrationsRes] = results
            if (SentryRes.status === 'fulfilled') {
                const integrations: any[] = []
                if (IntegrationsRes.status === 'fulfilled') {
                    integrations.push(new IntegrationsRes.value.Vue({ Vue, attachProps: true }))
                }
                SentryRes.value.init({
                    dsn,
                    integrations,
                    release: process.env.RELEASE_VERSION,
                    beforeSend(event: any, hint: any) {
                        if (hint && isErrorObject(hint.originalException)) {
                            const { hotelId, hotelCode, hotelName } = store.getters
                            event.user = {
                                hotelId: hotelId || '',
                                hotelCode: hotelCode || '',
                                hotelName: hotelName || ''
                            }
                            return event
                        }
                        return null
                    }
                })
            }
        })
    }
}
