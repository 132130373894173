import Vue from 'vue'
import Vuex from 'vuex'
import { RootState } from './interfaces'
import step1 from './step1/index'
import { stopAndClearStream } from '@/self-service-registration/components/compare-face/camera'

Vue.use(Vuex)

const store = new Vuex.Store<RootState>({
    modules: {
        step1
    },
    state: {
        step: 1
    },
    getters: {
        step: (state) => state.step
    },
    mutations: {
        SET_STEP(state, value: number) {
            state.step = value
        }
    },
    actions: {
        setStep({ commit, getters, dispatch }, value: number) {
            stopAndClearStream()
            // 再第三步点击返回时，直接跳转到第一步，并且清空数据
            if (getters.step === 3) {
                dispatch('reset')
                commit('SET_STEP', 1)
                return
            }
            commit('SET_STEP', value)
        }
    }
})

export default store
