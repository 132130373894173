



























import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'

export default Vue.extend({
    computed: {
        ...mapGetters(['hotelName', 'cardInfo'])
    },
    methods: {
        ...mapActions(['setStep']),
        submit() {
            this.setStep(1)
        }
    }
})
