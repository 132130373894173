import { importWithRetry } from '@/helper'
import { isProd } from '@/helper/is'

window._paq = window._paq || []

export class Tracker {
    private static matomo: Matomo
    private static tracker: MatomoAsyncTracker

    static init() {
        if (!isProd || Tracker.matomo || !process.env.VUE_APP_MATOMO_SITEID) return
        window._paq.push(['setTrackerUrl', 'https://matomo.whalepms.com/matomo.php'])
        window._paq.push(['setSiteId', process.env.VUE_APP_MATOMO_SITEID])
        // @ts-ignore
        importWithRetry<{ default: Matomo }>(() => import(/* webpackChunkName: "matomo" */ '@/libs/matomo'), 3, 200).then((res) => {
            window.Matomo = null
            Tracker.matomo = res.default
            Tracker.tracker = Tracker.matomo.getAsyncTracker()
            Tracker.tracker.enableLinkTracking()
            Tracker.trackRouter()
        })
    }

    private static trackRouter() {
        if (document.referrer) {
            Tracker.tracker.setReferrerUrl(document.referrer)
        }
        Tracker.tracker.setCustomUrl(window.location.href)
        Tracker.tracker.trackPageView(document.title)
    }
}
