


















































































import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'
import dayjs from 'dayjs'
import { Toast } from 'vant'
import { ComponentName } from './constants'
import { JiNingDictData } from '@/self-service-registration/http/api/index/interfaces'
import { isArray } from '@/helper/is'
import { getterKeys } from '@/self-service-registration/store/step1'

interface Column {
    id: string
    text: string
}

export default Vue.extend({
    name: ComponentName.JiNing,
    props: {
        jiNingDict: {
            type: Object,
            required: true
        },
        serverTime: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            strDDFSVisible: false,
            strDDFSColumns: [] as Column[],
            strDDFSText: '',
            strDDSSVisible: false,
            strDDSSMaxDate: dayjs(this.serverTime).toDate(),
            currentDate: dayjs(this.serverTime || Date.now()).toDate(),
            strLDFSVisible: false,
            strLDFSText: '',
            strLDFSColumns: [] as Column[],
            healthStates: [] as Column[]
        }
    },
    computed: {
        ...mapGetters(getterKeys)
    },
    created() {
        this.getColumns()
        // 设置隔离14天
        this.onChange(0, 'strSFGLJN')
        // 设置重点人员接触
        this.onChange(0, 'strSFJC')
    },
    methods: {
        ...mapMutations(['updateData']),
        onChange(value: string | number | string[], key: string) {
            this.updateData({ [key]: value })
        },
        onInputChange(e: Event, key: string) {
            const target = e.target as HTMLInputElement
            this.onChange(target.value, key)
        },
        getColumns() {
            const dict: JiNingDictData = this.jiNingDict
            const { strDDFSColumns, strLDFSColumns } = this
            if (isArray(dict.operationArriveType)) {
                dict.operationArriveType.forEach((c) => {
                    strDDFSColumns.push({ id: c.code, text: c.name })
                })
            }
            if (isArray(dict.operationComeType)) {
                dict.operationComeType.forEach((c) => {
                    strLDFSColumns.push({ id: c.code, text: c.name })
                })
            }
            if (isArray(dict.operationHealthState)) {
                const normal = dict.operationHealthState.find((c) => c.code === '1')
                if (normal) this.onChange(normal.code, 'strDQZK')
            }
        },
        onStrDDFSConfirm(e: Column) {
            this.onChange(e.id, 'strDDFS')
            this.strDDFSText = e.text
            this.strDDFSVisible = false
        },
        onStrDDSSConfirm(e: Date) {
            this.onChange(dayjs(e).format('YYYY-MM-DD'), 'strDDSS')
            this.strDDSSVisible = false
        },
        onStrLDFSConfirm(e: Column) {
            this.onChange(e.id, 'strLDFS')
            this.strLDFSText = e.text
            this.strLDFSVisible = false
        },
        onInputForTW(e: Event) {
            const target = e.target as HTMLInputElement
            if (target.value.length > 4) target.value = target.value.slice(0, 4)
        },
        onRadioClick(e: string, key: string) {
            if (e !== this[key]) {
                this.onChange(e, key)
            }
        },
        validate() {
            let errorMsg = ''
            const setErrorMsg = (msg: string) => {
                if (!errorMsg) errorMsg = msg
            }

            const { strDDFS, strDDFSXQ, strTJSS, strDDSS, strLDFS, strLDFSXQ, strDQTW, strDQZK, strTXRY } = this
            if (!strDDFS) setErrorMsg('请选择来济方式')
            if (!strDDFSXQ || !strDDFSXQ.trim()) setErrorMsg('请输入车次、航班、车牌号等')
            if (!strTJSS || !strTJSS.trim()) setErrorMsg('请输入路过或经停的省市')
            if (!strDDSS) setErrorMsg('请选择到达时间')
            if (!strLDFS) setErrorMsg('请选择来店方式')
            if (strLDFS === '9' && (!strLDFSXQ || !strLDFSXQ.trim())) setErrorMsg('请输入其他来店方式')
            if (!strDQTW) setErrorMsg('请输入当前体温')

            // 校验 0-99.9 只能输入一位小数
            if (strDQTW) {
                const result = strDQTW.split('.')
                if (result[result.length - 1] === '.') {
                    setErrorMsg('请输入正确的体温')
                } else {
                    if (result.length <= 2) {
                        const reg = /^[0-9]+(.[0-9]{1})?$/
                        if (!reg.test(result) || Number(result) > 99.9) {
                            setErrorMsg('请输入正确的体温')
                        }
                    } else {
                        setErrorMsg('请输入正确的体温')
                    }
                }
            }
            if (!strDQZK) setErrorMsg('请选择当前状况')
            if (!strTXRY || !strTXRY.trim()) setErrorMsg('请输入同行人员姓名和身份证号')

            if (errorMsg) {
                Toast(errorMsg)
                return false
            }
            return true
        }
    },
    watch: {
        strDDFS(val?: number) {
            if (!val) this.strDDFSText = ''
        },
        strLDFS(val?: number) {
            if (!val) this.strLDFSText = ''
        },
        strDQZK(val?: string) {
            if (!val) {
                const dict: JiNingDictData = this.jiNingDict
                if (isArray(dict.operationHealthState)) {
                    const normal = dict.operationHealthState.find((c) => c.code === '1')
                    if (normal) this.onChange(normal.code, 'strDQZK')
                }
                // 设置隔离14天
                this.onChange(0, 'strSFGLJN')
                // 设置重点人员接触
                this.onChange(0, 'strSFJC')
            }
        }
    }
})
